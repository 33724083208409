<template>
  <div class="container" :style="'top: ' + top">
    <div class="left">
      <span class="slogan main">雇人用，让电商技能交易更简单！</span>
      <span class="slogan">汇集海量电商技能人才</span>
      <img src="@/assets/imgs/bg.png" alt="">
    </div>
    <div class="form-container">
      <div class="header">
        <span class="login-label" @click="clickLogin" :class="{active: isLogin}">登录</span>
        <span class="login-label" @click="clickRegister" :class="{active: isRegister}">注册</span>
      </div>
      
      <div class="login" v-show="isLogin || isphoneLogin">
        <div class="loginTips" @click="loginChange">
          {{ isphoneLogin ? "账号密码登录" : "手机号快捷登录" }}
        </div>
        <el-form
            v-show="!isphoneLogin"
            :model="LoginUser"
            :rules="rules"
            status-icon
            ref="ruleForm"
            class="demo-ruleForm"
        >
          <el-form-item prop="name">
            <el-input
                prefix-icon="el-icon-user-solid"
                placeholder="请输入用户名"
                v-model="LoginUser.name"
            ></el-input>
          </el-form-item>
          <el-form-item prop="pass" style="margin-bottom: 10px;">
            <el-input
                prefix-icon="el-icon-view"
                type="password"
                placeholder="请输入密码"
                v-model="LoginUser.pass"
            ></el-input>
          </el-form-item>
          <el-form-item prop="agree">
            <el-checkbox v-model="LoginUser.agree">
              阅读并接受
              <router-link to="/UserAgreement" target="_blank">《用户协议》</router-link>
              与
              <router-link to="/Policy" target="_blank">《隐私保护政策》</router-link>
            </el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button
                size="medium"
                type="primary"
                @click="clickLoginBtn"
                style="width: 100%"
            >登录
            </el-button
            >
          </el-form-item>
          <el-row type="flex" justify="end">
            <el-link type="primary" @click="clickForgotPassword">忘记密码</el-link>
          </el-row>
          <!--<el-divider>第三方快捷登录</el-divider>-->
          <!--<el-row type="flex" justify="center">-->
          <!--  <img class="third" src="@/assets/imgs/wechat.png" alt="" />-->
          <!--</el-row>-->
        
        </el-form>
        
        <el-form
            v-show="isphoneLogin"
            :model="LoginUserPhone"
            :rules="rulesPhone"
            status-icon
            ref="ruleFormPhone"
            class="demo-ruleForm"
        >
          <el-form-item prop="userPhone">
            <el-input
                prefix-icon="el-icon-phone"
                placeholder="请输入手机号"
                v-model="LoginUserPhone.userPhone"
            ></el-input>
          </el-form-item>
          <el-form-item prop="smsCode" style="margin-bottom: 10px;">
            <el-input
                prefix-icon="el-icon-chat-dot-square"
                type="text"
                placeholder="短信验证码"
                v-model="LoginUserPhone.smsCode"
            >
              <template slot="append">
                <span class="sms" @click="send_sms">{{ sms_interval }}</span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="agree">
            <el-checkbox v-model="LoginUserPhone.agree">
              阅读并接受
              <router-link to="/UserAgreement" target="_blank">《用户协议》</router-link>
              与
              <router-link to="/Policy" target="_blank">《隐私保护政策》</router-link>
            </el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button
                size="medium"
                type="primary"
                @click="loginPhone"
                style="width: 100%"
            >登录
            </el-button
            >
          </el-form-item>
        </el-form>
      </div>
      
      <div class="register" v-show="isRegister">
        <el-form
            :model="RegisterUser"
            :rules="rulesRegister"
            status-icon
            ref="ruleFormRegister"
            class="demo-ruleForm"
        >
          <el-form-item prop="userPhone">
            <el-input prefix-icon="el-icon-phone" placeholder="请输入手机号"
                      v-model="RegisterUser.userPhone"></el-input>
          </el-form-item>
          <el-form-item prop="smsCode">
            <el-input prefix-icon="el-icon-chat-dot-square" type="text" placeholder="短信验证码"
                      v-model="RegisterUser.smsCode">
              <template slot="append">
                <span class="sms" @click="registerSendSms">{{ registerSmsInterval }}</span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password" style="margin-bottom: 10px;">
            <el-input prefix-icon="el-icon-view" type="password" placeholder="请设置登录密码"
                      v-model="RegisterUser.password"></el-input>
          </el-form-item>
          <el-form-item prop="agree">
            <el-checkbox v-model="RegisterUser.agree">
              阅读并接受
              <router-link to="/UserAgreement" target="_blank">《用户协议》</router-link>
              与
              <router-link to="/Policy" target="_blank">《隐私保护政策》</router-link>
            </el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button size="medium" type="primary" @click="clickRegisterBtn" style="width: 100%">注册</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  
  </div>

</template>

<script>
import { mapActions } from "vuex";
import { setToken } from "@/utils/auth";

export default {
  name: "login",
  
  data() {
    
    
    // 手机的校验方法
    let validatePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入手机号"));
      }
      // 用户名以字母开头,长度在5-16之间,允许字母数字下划线
      const phoneNameRule = /^1[3-9][0-9]{9}$/;
      if (phoneNameRule.test(value)) {
        this.is_send = true;
        return callback();
      } else {
        return callback(new Error("请输入正确手机号"));
      }
    };
    let validateRegisterPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入手机号"));
      }
      // 用户名以字母开头,长度在5-16之间,允许字母数字下划线
      const phoneNameRule = /^1[3-9][0-9]{9}$/;
      if (phoneNameRule.test(value)) {
        this.registerSmsSend = true;
        return callback();
      } else {
        return callback(new Error("请输入正确手机号"));
      }
    };
    // 用户名的校验方法
    let validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入用户名"));
      }
      // 用户名以字母开头,长度在5-16之间,允许字母数字下划线
      const userNameRule = /^\d{5,16}$/;
      if (userNameRule.test(value)) {
        this.$refs.ruleForm.validateField("checkPass");
        return callback();
      } else {
        return callback(new Error("长度5-16之间,允许字母数字下划线"));
      }
    };
    // 密码的校验方法
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入密码"));
      }
      // 密码以字母开头,长度在6-18之间,允许字母数字和下划线
      // const passwordRule = /^[5,17]$/;
      // passwordRule.test(value)
      if (5 < value.length < 18) {
        this.$refs.ruleForm.validateField("checkPass");
        return callback();
      } else {
        return callback(new Error("请输入长度6-18之间密码"));
      }
    };
    
    let validateConfirmPass = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入确认密码"));
      }
      // 密码以字母开头,长度在6-18之间,允许字母数字和下划线
      const passwordRule = /^[a-zA-Z]\w{5,17}$/;
      if (passwordRule.test(value)) {
        this.$refs.ruleForm.validateField("checkPass");
        return callback();
      } else {
        return callback(
            new Error("字母开头,长度6-18之间,允许字母数字和下划线")
        );
      }
    };
    let validateAgree = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请勾选接受用户协议"));
      }
      return callback();
    };
    let validateRegisterAgree = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请勾选接受用户协议"));
      }
      return callback();
    };
    
    return {
      LoginUser: {
        name: "",
        pass: "",
        agree: false,
      },
      LoginUserPhone: {
        userPhone: "",
        smsCode: "",
        agree: false,
      },
      RegisterUser: {
        userPhone: "",
        smsCode: "",
        password: "",
        agree: false,
        modal: null,
      },
      // change
      // 用户信息校验规则,validator(校验方法),trigger(触发方式),blur为在组件 Input 失去焦点时触发
      rules: {
        name: [{ validator: validateName, trigger: "blur" }],
        pass: [{ validator: validatePass, trigger: "blur" }],
        agree: [{ validator: validateAgree, trigger: "blur" }],
      },
      rulesPhone: {
        userPhone: [{ validator: validatePhone, trigger: "blur" }],
        smsCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        agree: [{ validator: validateAgree, trigger: "blur" }],
      },
      rulesRegister: {
        userPhone: [{ validator: validateRegisterPhone, trigger: "blur" }],
        smsCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        password: [{ validator: validateConfirmPass, trigger: "blur" }],
        agree: [{ validator: validateRegisterAgree, trigger: "blur" }],
      },
      isphoneLogin: false,
      sms_interval: "获取验证码",
      is_send: false,
      // avatarImg: "http://www.gurenyong.com/hiring_back/files/066fb0cf-ce68-4354-ac71-1ff4b388546f.jpg",
      registerSmsInterval: '获取验证码',
      registerSmsSend: false,
    };
  },
  computed: {
    // 获取vuex中的showLogin，控制登录组件是否显示
    isLogin: {
      get() {
        if (!this.$store.getters.getShowLogin && !this.isRegister) return true
        return this.$store.getters.getShowLogin;
      },
      set(val) {
        this.setShowLogin(val);
      },
    },
    isRegister: {
      get() {
        return this.$store.getters.getShowRegister;
      },
      set(val) {
        this.setShowRegister(val);
      },
    },
    showDialog: {
      get() {
        return this.$store.getters.getShowLogin || this.$store.getters.getShowRegister || this.isphoneLogin
      }
    },
    top() {
      return this.$store.getters.getStickyHeight
    },
  },
  methods: {
    ...mapActions([
      "setUser", "setShowLogin", "setShowRegister", "setToken",
      "onLogin", "onRegister"
    ]),
    clickForgotPassword() {
      this.$router.push({ name: 'forgotPassword' })
    },
    clickLoginBtn() {
      // 通过element自定义表单校验规则，校验用户输入的用户信息
      this.$refs["ruleForm"].validate((valid) => {
        //如果通过校验开始登录
        if (valid) {
          this.$axios
              .post(`/app-api/hiring/auth/login`, {
                    mobile: this.LoginUser.name,
                    password: this.LoginUser.pass
                  }
              )
              .then((res) => {
                // “0”代表登录成功，其他的均为失败
                if (res.data.code === 0) {
                  // 隐藏登录组件
                  this.setShowLogin(false);
                  // 登录信息存到本地
                  // let token = JSON.stringify(res.data.data);
                  // localStorage.setItem("token", token);
                  // 登录信息存到vuex
                  // this.setToken(res.data.data);
                  // 弹出通知框提示登录成功信息
                  // this.notifySucceed(res.data.message);
                  
                  this.getUserInfo();
                  setToken(res.data.data)
                  this.$router.push('/')
                } else {
                  // 清空输入框的校验状态
                  this.$refs["ruleForm"].resetFields();
                  // 弹出通知框提示登录失败信息
                  // this.notifyError(res.data.message);
                }
              })
              .catch((err) => {
                return Promise.reject(err);
              });
        } else {
          return false;
        }
      });
    },
    loginPhone() {
      // 通过element自定义表单校验规则，校验用户输入的用户信息
      this.$refs["ruleFormPhone"].validate((valid) => {
        //如果通过校验开始登录
        if (valid) {
          this.$axios
              .post(`/app-api/hiring/auth/sms-login`, {
                mobile: this.LoginUserPhone.userPhone,
                code: this.LoginUserPhone.smsCode
              })
              .then((res) => {
                // “0”代表登录成功，其他的均为失败
                if (res.data.code === 0) {
                  // 隐藏登录组件
                  this.setShowLogin(false);
                  this.isphoneLogin = false;
                  // // 登录信息存到本地
                  // let token = JSON.stringify(res.data.data);
                  // localStorage.setItem("token", token);
                  // // 登录信息存到vuex
                  // this.setToken(res.data.data);
                  // // 弹出通知框提示登录成功信息
                  // this.notifySucceed(res.data.message);
                  //
                  this.getUserInfo();
                  setToken(res.data.data)
                  this.$router.push('/')
                } else {
                  // 清空输入框的校验状态
                  this.$refs["ruleForm"].resetFields();
                  // 弹出通知框提示登录失败信息
                  // this.notifyError(res.data.message);
                }
              })
              .catch((err) => {
                return Promise.reject(err);
              });
        } else {
          return false;
        }
      });
    },
    getUserInfo() {
      this.$axios
          .get(`/app-api/hiring/user/token`)
          .then((res) => {
            // “0”代表获取用户成功，其他的均为失败
            if (res.data.code === 0) {
              // 用户信息存到本地
              let user = JSON.stringify(res.data.data);
              localStorage.setItem("user", user);
              // 登录信息存到vuex
              this.setUser(res.data.data);
              // 弹出通知框提示登录成功信息
              // this.notifySucceed(res.data.message);
              
              // 登录环信
              this.onLogin({
                username: res.data.data.userPhone,
                password: res.data.data.userPhone,
              })
              this.$router.push('/')
            } else {
              // 清空输入框的校验状态
              this.$refs["ruleForm"].resetFields();
              // 弹出通知框提示登录失败信息
              this.notifyError(res.data.message);
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    send_sms() {
      if (this.LoginUserPhone.userPhone === "") {
        return this.$message.warning("请输入手机号");
      }
      if (!this.LoginUserPhone.userPhone.match(/^1[3-9][0-9]{9}$/)) {
        return this.$message.warning("请输入正确手机号");
      }
      if (!this.is_send) return;
      this.is_send = false;
      let sms_interval_time = 60;
      this.sms_interval = "发送中...";
      this.$axios
          .post(`/app-api/hiring/auth/send-sms-code-login`, { mobile: this.LoginUserPhone.userPhone })
          .then((res) => {
            if (res.data.code === 0) {
              this.$message({
                message: "短信发送成功",
                type: "success",
              });
              let timer = setInterval(() => {
                if (sms_interval_time <= 1) {
                  clearInterval(timer);
                  this.sms_interval = "获取验证码";
                  this.is_send = true; // 重新回复点击发送功能的条件
                } else {
                  sms_interval_time -= 1;
                  this.sms_interval = `${sms_interval_time}秒后再发`;
                }
              }, 1000);
            } else if (res.data.code == -1) {
              this.sms_interval = "获取验证码";
            } else {
              this.$message({
                message: "短信发送失败，请稍后再试。。。",
                type: "error",
              });
              this.sms_interval = "获取验证码";
            }
          });
    },
    loginChange() {
      this.isphoneLogin = !this.isphoneLogin;
      this.isphoneLogin
          ? this.$refs["ruleForm"].resetFields()
          : this.$refs["ruleFormPhone"].resetFields();
    },
    clickLogin() {
      this.isphoneLogin
          ? this.$refs["ruleForm"].resetFields()
          : this.$refs["ruleFormPhone"].resetFields();
      this.setShowLogin(true);
      this.setShowRegister(false);
    },
    clickRegister() {
      this.$refs["ruleFormRegister"].resetFields();
      this.setShowLogin(false);
      this.setShowRegister(true);
      this.isphoneLogin = false
    },
    registerSendSms() {
      if (this.RegisterUser.userPhone === "") {
        return this.$message.warning("请输入手机号");
      }
      if (!this.RegisterUser.userPhone.match(/^1[3-9][0-9]{9}$/)) {
        return this.$message.warning("请输入正确手机号");
      }
      if (!this.registerSmsSend) return;
      this.registerSmsSend = false;
      let sms_interval_time = 60;
      this.registerSmsInterval = "发送中...";
      let timer = setInterval(() => {
        if (sms_interval_time <= 1) {
          clearInterval(timer);
          this.registerSmsInterval = "获取验证码";
          this.registerSmsSend = true; // 重新回复点击发送功能的条件
        } else {
          sms_interval_time -= 1;
          this.registerSmsInterval = `${sms_interval_time}秒后再发`;
        }
      }, 1000);
      
      this.$axios
          .post(`/app-api/hiring/auth/send-sms-code-register`, { mobile: this.RegisterUser.userPhone })
          .then((res) => {
            if (res.data.code === 0) {
              this.$message.success('短信发送成功');
            } else {
              // this.$message.error(res.data.msg);
              clearInterval(timer);
              this.registerSmsInterval = "获取验证码";
              this.registerSmsSend = true; // 重新回复点击发送功能的条件
            }
          });
    },
    clickRegisterBtn() {
      // 通过element自定义表单校验规则，校验用户输入的用户信息
      this.$refs["ruleFormRegister"].validate((valid) => {
        //如果通过校验开始注册
        if (valid) {
          this.$axios
              .post(`/app-api/hiring/auth/sms-register`, {
                mobile: this.RegisterUser.userPhone,
                code: this.RegisterUser.smsCode,
                password: this.RegisterUser.password
              })
              .then((res) => {
                // “0”代表注册成功，其他的均为失败
                if (res.data.code === 0) {
                  // 隐藏注册组件
                  this.setShowRegister(false);
                  // 弹出通知框提示注册成功信息
                  this.notifySucceed("注册成功");
                  setToken(res.data.data)
                  // 注册环信
                  // this.onRegister({
                  //   username: this.RegisterUser.userPhone,
                  //   password: this.RegisterUser.userPhone,
                  // })
                } else {
                  // 弹出通知框提示注册失败信息
                  // this.notifyError(res.data.msg);
                }
              })
              .catch((err) => {
                return Promise.reject(err);
              });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: #F7FBFE;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 60px 0;
  
  .left {
    display: flex;
    flex-direction: column;
    
    * {
      margin: 20px 0;
    }
    
    .slogan {
      font-size: 36px;
      
      &.main {
        font-size: 40px;
        font-weight: bold;
      }
    }
  }
  
  .form-container {
    margin: 20px 0;
    background-color: #ffffff;
    box-shadow: 14px 14px 20px -5px #DDEBF5;
    padding: 30px;
    
    .el-input {
    }
    
    a {
      color: #409EFF;
    }
    
    .header {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 30px;
      
      .login-label {
        padding-bottom: 5px;
        cursor: pointer;
        color: #999999;
        border-bottom: 4px solid transparent;
        
        &.active {
          color: #000000;
          border-bottom: 4px solid #FC3737;
        }
      }
    }
    
    .login, .register {
      width: 320px;
      text-align: center;
    }
    
    .el-form-item {
      margin: 18px 0;
    }
    
    .third {
      cursor: pointer;
    }
  }
}


.login-symbol {
  background: #888888;
  width: 1px;
  height: 16px;
  margin: 0 40px;
}

.login {
  position: relative;
  
  .loginTips {
    position: absolute;
    right: 0;
    top: -25px;
    color: #409EFF;
    cursor: pointer;
  }
}

.demo-ruleForm {
  margin-top: 40px;
}

.sms {
  color: orange;
  cursor: pointer;
  display: inline-block;
  width: 70px;
  text-align: center;
  user-select: none;
}

.el-checkbox > span {
  line-height: unset;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #606266;
}
</style>
